.featuresSection {
    --text-lock-up-button-alignment: flex-start;

    .left {
        max-inline-size: 500px;
    }

    .grid {
        inline-size: 100%;
        display: grid;
        gap: var(--space-32);
        grid-template-columns: repeat(auto-fill, minmax(min(234px, 100%), 1fr));

        li {
            h3 {
                margin: var(--space-4) 0 var(--space-8);
            }

            p {
                color: var(--display-onlight-secondary);
            }
        }
    }
}
